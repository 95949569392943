import React from 'react'
import { navigate } from 'gatsby'

import SignUpForm from 'components/Auth/SignUp'
import * as routes from 'constants/routes'
import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'
import PageTitle from 'components/PageTitle'
import { AuthService, DatabaseService } from 'services'

class SignUpPage extends React.Component {
  onSubmit = async (email, password, fname, lname) => {
    try {
      const authUser = await AuthService.doCreateUserWithEmailAndPassword(
        email,
        password
      )

      await DatabaseService.doCreateUser(authUser.user.uid, fname, lname, email)

      navigate(
        `/users/add_photo?redirectURL=${this.props.location.pathName ||
          routes.SEARCH}`,
        {
          replace: true,
          state: { ...(this.props.location.state || {}) },
        }
      )
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <>
        <PageTitle title="Sign Up" />
        <SignUpForm onSubmit={this.onSubmit} />
      </>
    )
  }
}

export default ({ location }) => (
  <Layout
    navbar="auth"
    navbarProps={{ showSearchBar: false }}
    location={location}
    displayFooter={false}
  >
    <SEO title="Create an account" />
    <SignUpPage />
  </Layout>
)
